import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5f78dcb2 = () => interopDefault(import('..\\pages\\dev-help\\index.vue' /* webpackChunkName: "pages/dev-help/index" */))
const _2e3f9b57 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _32764fe8 = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _447d3d83 = () => interopDefault(import('..\\pages\\backoffice\\authorization-error\\index.vue' /* webpackChunkName: "pages/backoffice/authorization-error/index" */))
const _19c1ef7e = () => interopDefault(import('..\\pages\\backoffice\\catalog\\index.vue' /* webpackChunkName: "pages/backoffice/catalog/index" */))
const _67d91e7e = () => interopDefault(import('..\\pages\\backoffice\\configuration\\index.vue' /* webpackChunkName: "pages/backoffice/configuration/index" */))
const _94118a3a = () => interopDefault(import('..\\pages\\backoffice\\dashboard\\index.vue' /* webpackChunkName: "pages/backoffice/dashboard/index" */))
const _4d19f065 = () => interopDefault(import('..\\pages\\backoffice\\media-library\\index.vue' /* webpackChunkName: "pages/backoffice/media-library/index" */))
const _1e41b1f5 = () => interopDefault(import('..\\pages\\backoffice\\my-profile\\index.vue' /* webpackChunkName: "pages/backoffice/my-profile/index" */))
const _eb734fc8 = () => interopDefault(import('..\\pages\\backoffice\\product-catalog\\index.vue' /* webpackChunkName: "pages/backoffice/product-catalog/index" */))
const _fbc5a18c = () => interopDefault(import('..\\pages\\backoffice\\seo\\index.vue' /* webpackChunkName: "pages/backoffice/seo/index" */))
const _774c241c = () => interopDefault(import('..\\pages\\backoffice\\shopping-cart\\index.vue' /* webpackChunkName: "pages/backoffice/shopping-cart/index" */))
const _ef0ebf10 = () => interopDefault(import('..\\pages\\backoffice\\cms\\components\\index.vue' /* webpackChunkName: "pages/backoffice/cms/components/index" */))
const _34b3a6f4 = () => interopDefault(import('..\\pages\\backoffice\\cms\\contents\\index.vue' /* webpackChunkName: "pages/backoffice/cms/contents/index" */))
const _61a3bec3 = () => interopDefault(import('..\\pages\\backoffice\\financial\\customer-credits\\index.vue' /* webpackChunkName: "pages/backoffice/financial/customer-credits/index" */))
const _48ad16a8 = () => interopDefault(import('..\\pages\\backoffice\\financial\\invoices\\index.vue' /* webpackChunkName: "pages/backoffice/financial/invoices/index" */))
const _628e8801 = () => interopDefault(import('..\\pages\\backoffice\\financial\\payments\\index.vue' /* webpackChunkName: "pages/backoffice/financial/payments/index" */))
const _57e256d7 = () => interopDefault(import('..\\pages\\backoffice\\order-creation\\estimate\\index.vue' /* webpackChunkName: "pages/backoffice/order-creation/estimate/index" */))
const _1fd93cb0 = () => interopDefault(import('..\\pages\\backoffice\\status-tracking\\job-orders\\index.vue' /* webpackChunkName: "pages/backoffice/status-tracking/job-orders/index" */))
const _0511fe6e = () => interopDefault(import('..\\pages\\backoffice\\status-tracking\\quotes\\index.vue' /* webpackChunkName: "pages/backoffice/status-tracking/quotes/index" */))
const _16c3497f = () => interopDefault(import('..\\pages\\backoffice\\status-tracking\\sales-orders\\index.vue' /* webpackChunkName: "pages/backoffice/status-tracking/sales-orders/index" */))
const _3275db07 = () => interopDefault(import('..\\pages\\backoffice\\order-creation\\custom-order\\item-type\\_id\\index.vue' /* webpackChunkName: "pages/backoffice/order-creation/custom-order/item-type/_id/index" */))
const _35f8dd83 = () => interopDefault(import('..\\pages\\backoffice\\cms\\components\\_componentId.vue' /* webpackChunkName: "pages/backoffice/cms/components/_componentId" */))
const _dc5b207a = () => interopDefault(import('..\\pages\\backoffice\\cms\\contents\\_contentId.vue' /* webpackChunkName: "pages/backoffice/cms/contents/_contentId" */))
const _ee926a16 = () => interopDefault(import('..\\pages\\backoffice\\order-creation\\custom-order\\_id.vue' /* webpackChunkName: "pages/backoffice/order-creation/custom-order/_id" */))
const _33f1542f = () => interopDefault(import('..\\pages\\backoffice\\product-catalog\\_productCategoryId.vue' /* webpackChunkName: "pages/backoffice/product-catalog/_productCategoryId" */))
const _65e7459c = () => interopDefault(import('..\\pages\\backoffice\\product\\_id\\index.vue' /* webpackChunkName: "pages/backoffice/product/_id/index" */))
const _6aa52278 = () => interopDefault(import('..\\pages\\backoffice\\catalog\\_id\\products\\index.vue' /* webpackChunkName: "pages/backoffice/catalog/_id/products/index" */))
const _6f6c9c4d = () => interopDefault(import('..\\pages\\_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dev-help",
    component: _5f78dcb2,
    name: "dev-help___en"
  }, {
    path: "/login",
    component: _2e3f9b57,
    name: "login___en"
  }, {
    path: "/logout",
    component: _32764fe8,
    name: "logout___en"
  }, {
    path: "/backoffice/authorization-error",
    component: _447d3d83,
    name: "backoffice-authorization-error___en"
  }, {
    path: "/backoffice/catalog",
    component: _19c1ef7e,
    name: "backoffice-catalog___en"
  }, {
    path: "/backoffice/configuration",
    component: _67d91e7e,
    name: "backoffice-configuration___en"
  }, {
    path: "/backoffice/dashboard",
    component: _94118a3a,
    name: "backoffice-dashboard___en"
  }, {
    path: "/backoffice/media-library",
    component: _4d19f065,
    name: "backoffice-media-library___en"
  }, {
    path: "/backoffice/my-profile",
    component: _1e41b1f5,
    name: "backoffice-my-profile___en"
  }, {
    path: "/backoffice/product-catalog",
    component: _eb734fc8,
    name: "backoffice-product-catalog___en"
  }, {
    path: "/backoffice/seo",
    component: _fbc5a18c,
    name: "backoffice-seo___en"
  }, {
    path: "/backoffice/shopping-cart",
    component: _774c241c,
    name: "backoffice-shopping-cart___en"
  }, {
    path: "/fr/dev-help",
    component: _5f78dcb2,
    name: "dev-help___fr"
  }, {
    path: "/fr/login",
    component: _2e3f9b57,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _32764fe8,
    name: "logout___fr"
  }, {
    path: "/backoffice/cms/components",
    component: _ef0ebf10,
    name: "backoffice-cms-components___en"
  }, {
    path: "/backoffice/cms/contents",
    component: _34b3a6f4,
    name: "backoffice-cms-contents___en"
  }, {
    path: "/backoffice/financial/customer-credits",
    component: _61a3bec3,
    name: "backoffice-financial-customer-credits___en"
  }, {
    path: "/backoffice/financial/invoices",
    component: _48ad16a8,
    name: "backoffice-financial-invoices___en"
  }, {
    path: "/backoffice/financial/payments",
    component: _628e8801,
    name: "backoffice-financial-payments___en"
  }, {
    path: "/backoffice/order-creation/estimate",
    component: _57e256d7,
    name: "backoffice-order-creation-estimate___en"
  }, {
    path: "/backoffice/status-tracking/job-orders",
    component: _1fd93cb0,
    name: "backoffice-status-tracking-job-orders___en"
  }, {
    path: "/backoffice/status-tracking/quotes",
    component: _0511fe6e,
    name: "backoffice-status-tracking-quotes___en"
  }, {
    path: "/backoffice/status-tracking/sales-orders",
    component: _16c3497f,
    name: "backoffice-status-tracking-sales-orders___en"
  }, {
    path: "/fr/backoffice/authorization-error",
    component: _447d3d83,
    name: "backoffice-authorization-error___fr"
  }, {
    path: "/fr/backoffice/catalog",
    component: _19c1ef7e,
    name: "backoffice-catalog___fr"
  }, {
    path: "/fr/backoffice/configuration",
    component: _67d91e7e,
    name: "backoffice-configuration___fr"
  }, {
    path: "/fr/backoffice/dashboard",
    component: _94118a3a,
    name: "backoffice-dashboard___fr"
  }, {
    path: "/fr/backoffice/media-library",
    component: _4d19f065,
    name: "backoffice-media-library___fr"
  }, {
    path: "/fr/backoffice/my-profile",
    component: _1e41b1f5,
    name: "backoffice-my-profile___fr"
  }, {
    path: "/fr/backoffice/product-catalog",
    component: _eb734fc8,
    name: "backoffice-product-catalog___fr"
  }, {
    path: "/fr/backoffice/seo",
    component: _fbc5a18c,
    name: "backoffice-seo___fr"
  }, {
    path: "/fr/backoffice/shopping-cart",
    component: _774c241c,
    name: "backoffice-shopping-cart___fr"
  }, {
    path: "/fr/backoffice/cms/components",
    component: _ef0ebf10,
    name: "backoffice-cms-components___fr"
  }, {
    path: "/fr/backoffice/cms/contents",
    component: _34b3a6f4,
    name: "backoffice-cms-contents___fr"
  }, {
    path: "/fr/backoffice/financial/customer-credits",
    component: _61a3bec3,
    name: "backoffice-financial-customer-credits___fr"
  }, {
    path: "/fr/backoffice/financial/invoices",
    component: _48ad16a8,
    name: "backoffice-financial-invoices___fr"
  }, {
    path: "/fr/backoffice/financial/payments",
    component: _628e8801,
    name: "backoffice-financial-payments___fr"
  }, {
    path: "/fr/backoffice/order-creation/estimate",
    component: _57e256d7,
    name: "backoffice-order-creation-estimate___fr"
  }, {
    path: "/fr/backoffice/status-tracking/job-orders",
    component: _1fd93cb0,
    name: "backoffice-status-tracking-job-orders___fr"
  }, {
    path: "/fr/backoffice/status-tracking/quotes",
    component: _0511fe6e,
    name: "backoffice-status-tracking-quotes___fr"
  }, {
    path: "/fr/backoffice/status-tracking/sales-orders",
    component: _16c3497f,
    name: "backoffice-status-tracking-sales-orders___fr"
  }, {
    path: "/fr/backoffice/order-creation/custom-order/item-type/:id",
    component: _3275db07,
    name: "backoffice-order-creation-custom-order-item-type-id___fr"
  }, {
    path: "/backoffice/order-creation/custom-order/item-type/:id",
    component: _3275db07,
    name: "backoffice-order-creation-custom-order-item-type-id___en"
  }, {
    path: "/fr/backoffice/cms/components/:componentId",
    component: _35f8dd83,
    name: "backoffice-cms-components-componentId___fr"
  }, {
    path: "/fr/backoffice/cms/contents/:contentId",
    component: _dc5b207a,
    name: "backoffice-cms-contents-contentId___fr"
  }, {
    path: "/fr/backoffice/order-creation/custom-order/:id?",
    component: _ee926a16,
    name: "backoffice-order-creation-custom-order-id___fr"
  }, {
    path: "/backoffice/cms/components/:componentId",
    component: _35f8dd83,
    name: "backoffice-cms-components-componentId___en"
  }, {
    path: "/backoffice/cms/contents/:contentId",
    component: _dc5b207a,
    name: "backoffice-cms-contents-contentId___en"
  }, {
    path: "/backoffice/order-creation/custom-order/:id?",
    component: _ee926a16,
    name: "backoffice-order-creation-custom-order-id___en"
  }, {
    path: "/fr/backoffice/product-catalog/:productCategoryId?",
    component: _33f1542f,
    name: "backoffice-product-catalog-productCategoryId___fr"
  }, {
    path: "/fr/backoffice/product/:id",
    component: _65e7459c,
    name: "backoffice-product-id___fr"
  }, {
    path: "/fr/backoffice/catalog/:id/products",
    component: _6aa52278,
    name: "backoffice-catalog-id-products___fr"
  }, {
    path: "/backoffice/product-catalog/:productCategoryId?",
    component: _33f1542f,
    name: "backoffice-product-catalog-productCategoryId___en"
  }, {
    path: "/backoffice/product/:id",
    component: _65e7459c,
    name: "backoffice-product-id___en"
  }, {
    path: "/backoffice/catalog/:id/products",
    component: _6aa52278,
    name: "backoffice-catalog-id-products___en"
  }, {
    path: "/fr/*",
    component: _6f6c9c4d,
    name: "all___fr"
  }, {
    path: "/*",
    component: _6f6c9c4d,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
